import { getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";
import { app } from "./support";
var msg:Messaging | null=null

try{
msg=getMessaging(app)
}
catch(e){
console.warn("Messaging will not work here...")
console.error(e)
}
export default function messaging(){
    return{
        onMessage:(handler:any)=>!!msg && onMessage(msg,handler),
        setBackgroundMessageHandler:(handler:any)=>{},
        getToken:()=>!!msg && getToken(msg)
    }
}
export const firebase={

}