// react-native compatible layer for persistent storage.
const AsyncStorage ={
getAllKeys:()=>{
    let out:Array<any>=[]
    for(let k=0;k<localStorage.length;k++){
        out.push(localStorage.key(k))
    }

    return new Promise(r=>r(out))
},
multiGet:(keys:Array<any>)=>{
    let res=   keys.map(key=>[key,localStorage.getItem(key)])
    return new Promise<any>(r=>r(res))
},

setItem:(key:any,value:any)=>{
    localStorage.setItem(key,value)
    return new Promise<any>(r=>r(undefined))
},

removeItem:(key:any)=>{
    localStorage.removeItem(key)
    return new Promise<any>(r=>r(undefined))
},

getItem:(key:any)=>{
    return new Promise<any>(r=>r(localStorage.getItem(key)))
},

}


export default AsyncStorage