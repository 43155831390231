import { coreOptions } from "core/core";
import { FULL_SCREEN, fs, rgba, rh, rw } from "core/designHelpers";
import navhelper from "core/navhelper";
import { useState } from "react";
import { Alert } from "react-native";
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  View,
  ActivityIndicator,
  Text,
  TouchableOpacity,
} from "react-native";

import cloud from "src/cloud";
import Header from "src/components/Header";
import TextField from "src/components/TextField";

interface IResetPasswordScreenProps {
  email: string;
  componentId: string;
  provider: boolean;
}
export default function ResetPasswordScreen({
  componentId,
  ...props
}: IResetPasswordScreenProps) {
  const [email, setEmail] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<any>({});
  const [loading, setloading] = useState(false);

  return (
    <SafeAreaView style={FULL_SCREEN}>
      <Header
        title="Forgot Password"
        leftButton={{
          title: "Login",
          onPress: () => navhelper.goBack(),
        }}
      />

      <TextField
        label="Email address"
        value={email}
        placeholder="Email address"
        onChangeText={(newEmail) => setEmail(newEmail?.trim())}
        errorMsg={errors["email"]}
      />
      <View style={{ paddingHorizontal: rw(16), marginTop: rh(16) }}></View>

      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1, justifyContent: "flex-end" }}
      >
        <TouchableOpacity
          disabled={!email ? true : false}
          style={{
            width: rw(343),
            marginHorizontal: rw(16),
            backgroundColor: !email
              ? rgba(128, 128, 128, 1)
              : rgba(47, 72, 88, 1),
            height: rh(54),
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={async () => {
            setloading(true);
            await cloud
              .sendVerificationEmail({
                email,
                type: "pwd",
                isProvider: props.provider,
              })
              .then((res) => {
                setloading(false);
                setTimeout(() => {
                  Alert.alert(
                    "Success",
                    "We have successfully sent the email. Please check the inbox. Make sure the mail is not in the junk box."
                  );
                  navhelper.goBack();
                }, 1000);
              })
              .catch((err) => {
                if (err) {
                  setloading(false);
                }
              });
          }}
        >
          {loading ? (
            // @ts-ignore
            <ActivityIndicator color={props.style?.color || "white"} />
          ) : (
            <Text
              style={{
                fontFamily: "Outfit",
                fontSize: fs(14),
                fontWeight: "600",
                color: "white",
              }}
            >
              Send Password Reset Mail
            </Text>
          )}
        </TouchableOpacity>
        <View style={{ height: rh(20) }} />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
coreOptions(ResetPasswordScreen, {
  noBottomBar: true,
});
