

export default class env_stage{

    static   type:'mock' | 'dev' | 'prod'='prod'
    static serverURL='http://localhost:5001/game-on--app-llc/us-central1/'

    static  NO_PAY=true

    static DO_NOT_REQUIRE_DOCS=true
}
   