import { fs, FULL_SCREEN, rgba, rh, rw } from "core/designHelpers";
import navhelper from "core/navhelper";
import StyleSheetRW from "core/StyleSheetRW";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import AppButton from "src/components/AppButton";
import Header from "src/components/Header";

import { coreOptions } from "core/core";
import selected_png from "res/img/plan_selected.png";
import clientStorage from "core/clientStorage";
import ConsumerTabs from "./consumer/tabs/ConsumerTabs";
import cloud from "src/cloud";
import axios from "axios";
import env from "res/env";

export default function HearAboutUs({ componentId }: any) {
  let id = 0;

  const [currentSelected, setcurrentSelected] = useState<number>(0);

  const submitButtonClicked = async () => {
    console.log("here currentSelected ", currentSelected);
    if (currentSelected === 0) {
      Alert.alert("Error", "Please select one option");
    } else {
      var selectedValue = "";
      if (currentSelected === 1) {
        selectedValue = "Billboard";
      } else if (currentSelected === 2) {
        selectedValue = "Commercial";
      } else if (currentSelected === 3) {
        selectedValue = "Social Media";
      } else if (currentSelected === 4) {
        selectedValue = "Search Engine";
      } else if (currentSelected === 5) {
        selectedValue = "Word Of Mouth";
      }
      clientStorage.saveItem("hearAboutUs", selectedValue);
      var email = "";
      let userEmail = clientStorage.getItem("lastSuccessEmail");
      console.log("🚀 ~ file: HearAboutUs.tsx:45 ~ submitButtonClicked ~ userEmail:", userEmail);
      if (userEmail !== undefined && userEmail !== null) {
        email = userEmail;
      }
      updateSurveyInfo(email, selectedValue);
      navhelper.setRoot(ConsumerTabs);
    }
  };

  const updateSurveyInfo = async (emailValue: string, surveyVal: string) => {
    console.log("🚀 ~ file: HearAboutUs.tsx:54 ~ updateSurveyInfo ~ emailValue:", emailValue, surveyVal);
    let headers: any = {};
    await axios
      .post(
        env.serverURL + "surveyInfo",
        { email: emailValue, surveyVal: surveyVal },
        {
          headers,
        }
      )
      .then((r) => {})
      .catch((e) => {});
  };

  const currentItemSelected = (value: number) => {
    setcurrentSelected(value);
  };

  return (
    <View style={[FULL_SCREEN]}>
      <SafeAreaView style={FULL_SCREEN}>
        <Header title="Survey Info" noPadHorizontal noBack={true} />

        <Text
          style={{
            color: "#333",
            fontFamily: "Outfit",
            fontSize: fs(20),
            fontWeight: "600",
            marginLeft: rw(10),
            marginBottom: 20,
          }}
        >
          Where did you hear about us?
        </Text>

        <TouchableWithoutFeedback onPress={() => currentItemSelected(1)}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.planBox,
                { backgroundColor: currentSelected === 1 ? rgba(47, 72, 88, 1) : rgba(255, 255, 255, 0.5) },
              ]}
            >
              <Text
                style={{
                  color: currentSelected === 1 ? "white" : "#333",
                  fontFamily: "Outfit",
                  fontSize: fs(16),
                  fontWeight: "400",
                }}
              >
                Billboard
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={() => currentItemSelected(2)}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.planBox,
                { backgroundColor: currentSelected === 2 ? rgba(47, 72, 88, 1) : rgba(255, 255, 255, 0.5) },
              ]}
            >
              <Text
                style={{
                  color: currentSelected === 2 ? "white" : "#333",
                  fontFamily: "Outfit",
                  fontSize: fs(16),
                  fontWeight: "400",
                }}
              >
                Commercial
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={() => currentItemSelected(3)}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.planBox,
                { backgroundColor: currentSelected === 3 ? rgba(47, 72, 88, 1) : rgba(255, 255, 255, 0.5) },
              ]}
            >
              <Text
                style={{
                  color: currentSelected === 3 ? "white" : "#333",
                  fontFamily: "Outfit",
                  fontSize: fs(16),
                  fontWeight: "400",
                }}
              >
                Social Media
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={() => currentItemSelected(4)}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.planBox,
                { backgroundColor: currentSelected === 4 ? rgba(47, 72, 88, 1) : rgba(255, 255, 255, 0.5) },
              ]}
            >
              <Text
                style={{
                  color: currentSelected === 4 ? "white" : "#333",
                  fontFamily: "Outfit",
                  fontSize: fs(16),
                  fontWeight: "400",
                }}
              >
                Search Engine
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={() => currentItemSelected(5)}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.planBox,
                { backgroundColor: currentSelected === 5 ? rgba(47, 72, 88, 1) : rgba(255, 255, 255, 0.5) },
              ]}
            >
              <Text
                style={{
                  color: currentSelected === 5 ? "white" : "#333",
                  fontFamily: "Outfit",
                  fontSize: fs(16),
                  fontWeight: "400",
                }}
              >
                Word Of Mouth
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
        <View style={{ flex: 1, justifyContent: "flex-end" }}>
          <View
            style={
              Platform.OS == "android"
                ? { alignItems: "center", paddingTop: rh(5), height: rh(108), marginBottom: rh(20) }
                : { marginBottom: rh(10), bottom: 30 }
            }
          >
            <AppButton onPress={() => submitButtonClicked()} title="Submit" enabled={true} />
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}

coreOptions(HearAboutUs, {
  useSafeAreaView: true,
  noBottomBar: true,
});

const styles = StyleSheetRW.create(() => ({
  planBox: {
    height: rh(54),
    marginTop: 10,
    marginHorizontal: 15,
    paddingHorizontal: rw(15),
    paddingVertical: rh(15),
    backgroundColor: "rgba(255,255,255,0.5)",
    borderRadius: 40,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: rh(10),
  },
}));
