import { coreOptions } from "core/core";
import { rw } from "core/designHelpers";
import { useEffect, useState } from "react";
import {
  Platform,
} from "react-native";
import types from "res/refGlobalTypes";
import Header from "src/components/Header";
import navhelper from "core/navhelper";
import cloud from "src/cloud";
import ActiveProvider from "./tabs/Home/ActiveProvider";
import { Session } from "src/commons";
import { getAppStore, updateStore } from "src/models/ReduxStore";
import { Navigation } from "react-native-navigation";

interface SelectedProfDetailsScreen {
  provider: types.IProvider;
}

export default function SelectedProfDetailsScreen(
  props: SelectedProfDetailsScreen
) {
  const { provider } = props;
  const [schedules, setSchedules] = useState<Array<any>>([]);
  const [activeProvider, setActiveProvider] =
    useState<types.IProvider>(provider);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Platform.OS != "web") {
      let s = Navigation.events().registerComponentDidAppearListener(() => {
        onFocus();
      });

      return () => s.remove();
    } else onFocus();
  }, []);

  const onFocus = () => {
    setLoading(true);

    cloud.getUser({ version: "1.0.0" }).then((x) => {
      updateStore({
        user: { ...getAppStore((p) => p.user), ...x, verified: !!x?.verified },
      });
      if (!x.bookingId) {
        navhelper.goBack();
      } else {
        Promise.all([
          cloud.getProviderForBooking({ bookingId: x.bookingId }).then((x) => {
            __DEV__ && console.log("provider", x.email);
            updateStore({ active_provider: x });
            if (x.feedbackPending && !Session.feedbackSkipped) {
              navhelper.push("AppRatingScreen", { provider: x });
            } else {
              Session.feedbackSkipped = false;
              setActiveProvider(x);
            }
          }),
          cloud.getSchedules({ bookingId: x.bookingId }).then((x) => {
            updateStore({ schedules: x });

            setSchedules(x);
          }),
        ])
        .finally(() => setLoading(false));
      }
    });
  };

  if (!provider) return null;
  return (
    <>
      <Header title="Booked Professional" noPadHorizontal />
      <ActiveProvider
        schedules={[...schedules]}
        onFocus={onFocus}
        setSchedules={setSchedules}
        provider={activeProvider}
      />
    </>
  );
}
coreOptions(SelectedProfDetailsScreen, {
  useSafeAreaView: true,
  noBottomBar: true,
  getBodyStyle: () => ({ paddingHorizontal: rw(16) }),
});


