
import {getDownloadURL, getStorage,ref,uploadBytes} from "firebase/storage"
import { app } from "./support"
const _storage=getStorage(app)

const storage=()=>{

    
    return {

        ref:(...args:any)=>{
            const _ref=ref(_storage,...args)
        
            return {..._ref, 
                putFile:(loc:string | any)=>uploadBytes(_ref,loc as File),
                getDownloadURL:()=>getDownloadURL(_ref)
            }
        }
    }

}

export default storage