import { useEffect } from "react";
import { ImageBackground } from "react-native";
import { Navigation } from "react-native-navigation";
import { Outlet } from "react-router-dom";
import {useNavigate} from 'react-router-dom'
import launch_Background_jpg from "res/img/launch_Background.jpg"
const Layout = () => {
   const navigate= useNavigate()

   useEffect(()=>{
    Navigation.setNavigate(navigate)
   },[navigate])
  return (

<ImageBackground source={launch_Background_jpg} resizeMode="stretch" >
      <Outlet />
    </ImageBackground>
  );
};

export default Layout;