// It is web registry
import AppNew from "AppNew";
import { connectCore } from "core/core";
import Layout from "Layout";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ResetPassword from "ResetPassword";
import Response from "Response";
import { APP_SCREENS } from "Shared";
import { registerScreens } from "src/ScreenRegistry";
import WelcomeScreenIn from "src/screens/WelcomeScreen";
require('AppMobile') // initialize the mobile application.



const WelcomeScreen=connectCore(WelcomeScreenIn)

export default function Links(){
  
    return (<BrowserRouter>
    <Routes>
    <Route path='/' element={<Layout/>}>
      <Route index element={<AppNew/>}/>
      <Route path="response" element={<Response/>}/>
      <Route path="resetPassword" element={<ResetPassword/>}/>
      {APP_SCREENS.map(({name,getComponent})=>{
        let Component=getComponent()
        return(<Route key={name} path={name} element={<Component componentId="1" />} />)
      })}
    </Route>
    </Routes>
    </BrowserRouter>)
 }