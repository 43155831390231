/* ConHeader short for ConsumerHeader */

import clientStorage from "core/clientStorage";
import { fs, rh, rw } from "core/designHelpers";
import navhelper from "core/navhelper";
import Pic from "core/Pic";
import StyleSheetRW from "core/StyleSheetRW";
import React, { useEffect, useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import colors from "res/colors";
import notification_svg from "res/svgs/notification.svg";
import notification_unread_svg from "res/svgs/notification_unread.svg";
// import { useAppMode } from "src/commons";
import { useAppStore } from "src/models/ReduxStore";
interface ConHeaderprops {
  title: string;
  hideNotification?: boolean;
  rightComponent?: any;
  onPress: () => any;
}
var listeners: Array<any> = [];
var _IsUnread =
  clientStorage.getItem("NotificationRead") === undefined ||
  clientStorage.getItem("NotificationRead") === null
    ? true
    : false;
function _ConHeader({
  title,
  hideNotification,
  rightComponent,
  onPress,
}: ConHeaderprops) {
  const user = useAppStore((p) => p.user);

  let [IsUnread, setUnread] = useState(false);
  let [isNotificationShow, setisNotificationShow] = useState(false);

  let RightComponent = rightComponent;
  const mode = "no_sub";

  useEffect(() => {
    if (mode === "no_sub"  || mode === "trial" || mode === "trial_completed") {
      checkNotifications();
    } else {
      setisNotificationShow(false);
    }
  }, []);

  const checkNotifications = () => {
    let currentTime = Date.now();
    if (user?.created_on !== undefined) {
      var myDate = new Date(user?.created_on!);
      var result = myDate.getTime();

      var mainDiference = currentTime - result;

      if (mainDiference > 86400 * 1000 * 4) {
        setisNotificationShow(true);
      }
    }
  };

  useEffect(() => {
    listeners.push(setUnread);
    return () => {
      listeners = listeners.filter((x) => x != setUnread);
    };
  }, []);

  return (
    <View style={styles.body}>
      <Text style={styles.title}>{title}</Text>
        <Pressable onPress={() => onPress()}>
          <Pic
            source={
              clientStorage.getItem("NotificationRead") === undefined ||
              clientStorage.getItem("NotificationRead") === null ||
              IsUnread === true
                ? notification_unread_svg
                : notification_svg
            }
            style={styles.notification}
          />
        </Pressable>
      {!!rightComponent && <RightComponent />}
    </View>
  );
}
const ConHeader: typeof _ConHeader & { setUnread: (value: boolean) => void } =
  React.memo(_ConHeader, () => true);
ConHeader.setUnread = (value: boolean) => {
  clientStorage.saveItem("NotificationRead", false);
  listeners.forEach((l) => l(value));
};

const styles = StyleSheetRW.create(() => ({
  body: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: rh(10),
  },
  title: {
    fontFamily: "Outfit",
    fontWeight: "600",
    fontSize: fs(35),
    color: colors.darkGreen,
  },
  notification: {
    height: rh(28),
    width: rw(28),
  },
}));

export default ConHeader;
