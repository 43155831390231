import clientStorage from "core/clientStorage";
import { coreOptions } from "core/core";
import { FULL_SCREEN, rh, rw } from "core/designHelpers";
import navhelper from "core/navhelper";
import { useEffect, useState } from "react";
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  View,
} from "react-native";

import cloud from "src/cloud";
import AppButton from "src/components/AppButton";
import Header from "src/components/Header";
import InfoBar from "src/components/InfoBar";
import TextField from "src/components/TextField";
import { getAppStore, updateStore, useAppStore } from "src/models/ReduxStore";
import { makeDBPath, onUpdateOnce } from "src/commons";

interface IVerifyEmailScreenProps {
  email: string;
  componentId: string;
}
var active = false;
export default function VerifyEmailScreen({
  componentId,
  ...props
}: IVerifyEmailScreenProps) {
  const user = useAppStore((p) => p.user)!;
  const [email, setEmail] = useState<string>(user.email);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<any>({});

  let Source = user?.source?.charAt(0).toUpperCase()! + user?.source?.slice(1);
  useEffect(() => {
    active = true;
    return () => {
      active = false;
    };
  });
  return (
    <SafeAreaView style={FULL_SCREEN}>
      <Header title="Verify Email" />

      <TextField
        label="Contact Email"
        value={email}
        placeholder="Enter your new email address"
        onChangeText={(newEmail) => setEmail(newEmail?.trim())}
        errorMsg={errors["email"]}
        editable={!user.source}
      />
      <View style={{ paddingHorizontal: rw(16), marginTop: rh(16) }}>
        <InfoBar
          data={{
            text: user.source
              ? "This account is created using third party identity provider " +
                Source +
                " and the email can not be changed"
              : "You can change your email before verification. Once verified you can not change it.",
            textStyle: { fontWeight: "400" },
          }}
        />
      </View>

      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1, justifyContent: "flex-end" }}
      >
        <AppButton
          onPress={async () => {
            if (email?.toLowerCase() !== user.email?.toLowerCase()) {
              await cloud.user.changeEmail({ newEmail: email?.toLowerCase() });
              let { token } = await cloud.login({
                email: email.toLowerCase(),
                password: clientStorage.getItem("lastPassword"),
              });
              updateStore({
                user: { ...user, email: email.toLowerCase(), token },
              });
              clientStorage.saveItem("user", {
                ...user,
                email: email.toLowerCase(),
                token,
              });
            }
            await cloud.sendVerificationEmail({ email: email?.toLowerCase() });
            onUpdateOnce(
              makeDBPath("users", email?.toLowerCase(), "verified"),
              (v) => {
                let store_user = getAppStore((p) => p.user)!;
                if (v.val()) {
                  console.log("Active", active);
                  !store_user?.verified &&
                    updateStore({ user: { ...store_user, verified: true } });
                  Alert.alert(
                    "Verified",
                    "Thank you your email has been verified!"
                  );
                  active && navhelper.push("BeforeQuestionVideoScreen");
                }
              }
            );
            Alert.alert(
              "Success",
              "We have successfully sent the email. Please check the inbox. Make sure the mail is not in the junk box."
            );
          }}
          title="Send Verification Mail"
          enabled={!email ? false : undefined}
        />
        <View style={{ height: rh(20) }} />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
coreOptions(VerifyEmailScreen, {
  noBottomBar: true,
});
