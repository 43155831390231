import { coreOptions } from "core/core";
import { fs, rh, rw } from "core/designHelpers";
import StyleSheetRW from "core/StyleSheetRW";
import { Image, ScrollView, Text, View } from "react-native";
import Header from "src/components/Header";
import AppButton from "src/components/AppButton";
import frame_png from "res/img/frame.png";

interface SubscriptionInfoScreenprops {}

export default function SubscriptionInfoScreen(
  props: SubscriptionInfoScreenprops
) {
  return (
    <>
      <Header title="Welcome" noPadHorizontal />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ height: rh(150) }}>
          <Image source={frame_png} style={styles.elipse} />
        </View>
        <View style={{ height: rh(160) }}>
          <Text style={styles.text}>
            Congratulation, your profile has been created & completed
          </Text>
          <Text style={styles.text}>
            To access all that Game On! has to offer. You need to choose a
            subscription plan, but Game On! is free for your first consultation
            and we wouldn't bill you untill after your first call.
          </Text>
        </View>
        <View style={{ height: rh(300) }} />
        <AppButton
          style={{ marginHorizontal: 0, backgroundColor: "#2F4858" }}
          title="Proceed"
          // onPress={async () => {

          //     return new Promise(r => {
          //         Alert.alert("Delete Profile", "Do you want to delete your profile? This can not be undone!", [
          //             {
          //                 text: "No",
          //                 onPress: r
          //             },
          //             {
          //                 text: "Yes",
          //                 onPress: () => {
          //                     cloud.updateUser({ deleted: true, email: user?.email! })
          //                         .then(() => { Logout() })
          //                         .finally(r)

          //                 }
          //             }
          //         ])
          //     })
          // }}
        />
      </ScrollView>
    </>
  );
}
coreOptions(SubscriptionInfoScreen, {
  useSafeAreaView: true,
  noBottomBar: true,
  getBodyStyle: () => ({ paddingHorizontal: rw(16) }),
});
const styles = StyleSheetRW.create(() => ({
  elipse: {
    backgroundColor: "#E9FAEF",
    borderRadius: 100,
    height: rh(150),
    width: rw(150),
    marginLeft: rw(95),
    position: "absolute",
  },
  title: {
    fontFamily: "Outfit",
    fontWeight: "400",
    fontSize: fs(20),
    color: "#333333",
    marginBottom: rh(5),
    marginTop: rh(30),
    textAlign: "center",
  },
  text: {
    fontFamily: "Outfit",
    fontWeight: "400",
    fontSize: fs(18),
    color: "#2F4858",
    marginBottom: rh(5),
    marginTop: rh(30),
    textAlign: "center",
    lineHeight: "1.5",
  },
}));
