import { APP_SCREENS } from "Shared"

/// react-native-navigation module resolution
export type Options=any

var navigate:any=null

interface IStack{
    stack:{children:Array<{component:{name:string , [key :string]:any}}>}
}

interface IRoot{
    root:IStack
}

interface ITab{
    root:{bottomTabs:{children:Array<IStack>,id?:string,options:any }}
}
var queue:Array<any>=[]
var tabs:Array<IStack>=[]
export const Navigation={
    setNavigate(newNavigate:any)
    {
        navigate=newNavigate
        if (navigate){

            let waiting=queue.pop()
            if (waiting)
            navigate(waiting)
            queue.length=0
        }
    },
    setRoot(o:ITab | IRoot){
        let simpleRoot=o as IRoot
        let tab=o as ITab
        if (simpleRoot.root.stack)
        {
            Navigation.push(undefined,simpleRoot.root.stack.children[0])
        }
        else if (tab.root.bottomTabs.children) {
            tabs= tab.root.bottomTabs.children
            Navigation.push(undefined,tab.root.bottomTabs.children[0].stack.children[0])
        }
        else 
        {
            console.error("set root unhandled for ",o)
        }


    },
    setDefaultOptions(o:any){

    },
    registerComponent(name:string,getComponent:()=>any,mutant?:any){

        APP_SCREENS.push({name,getComponent})
    },
    events(){
        return {
            registerAppLaunchedListener:(o:any)=>{

            },
            registerComponentDidAppearListener:(o:any)=>{

            }
        }
    },
    push(i:any,o:{component:{name:string,id?:string,passProps?:any}}){
        //const navigate=useNavigate()
        let path="/"+o.component.name
        navigate ? navigate(path)
                : queue.push(path)

    },
    pop(o?:any){
        navigate(-1)
    },
    mergeOptions(id:string,options:any){
        let {bottomTabs:{currentTabIndex}}=options
        Navigation.push(undefined,tabs[currentTabIndex].stack.children[0])
    }
    
}

export type LayoutStack =any