import React, { useEffect, useState } from "react";

var currentURL=""
export default function WebView(props:any){

    const [text,setText]=useState("Purchase in progress. Please continue your purchase in the popup and do not close this tab while purchase is in progress.")
    
    useEffect(()=>{
        let Window= window.open(props.source.uri,'popup',`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=0,height=0,left=0,top=0`)
    
       if (Window){
        let ref=setInterval(()=>{

            if (Window?.closed){
                setText("Purchase popup has been closed and no purchase has been made. You can go back safely.")
                clearInterval(ref)
            }
            else {
                try{
                    if (Window?.location.href!=currentURL){
                        const url=Window?.location.href || ""
                        if (url.includes("ping"))
                        {
                            Window?.close()
                            clearInterval(ref)
                        }
                        props.onNavigationStateChange({url})
                        currentURL=Window?.location.href!
                    }
                }
                catch(_){

                }
            }
            
        },1000)
       
       }

       return ()=>{
        Window?.close()
       }
    },[])
  
    return (<label>{text}</label>)


}