import { useEffect, useState } from "react";
import { ActivityIndicator, Platform, Text, View } from "react-native";
import { fs, rgba, rw } from "core/designHelpers";
import navhelper from "core/navhelper";
import { TouchableOpacity } from "react-native";
import { coreOptions } from "core/core";
import ConHeader from "src/components/ConHeader";

import { Navigation } from "react-native-navigation";

import { FlatList } from "react-native";
import { useAppStore } from "src/models/ReduxStore";
import Header from "src/components/Header";
import clientStorage from "core/clientStorage";

interface NotificationScreenProps {
  componentId: any;
}

export default function NotificationScreen(props: NotificationScreenProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [notificationData, setnotificationData] = useState<Array<string>>();
  const user = useAppStore((p) => p.user);

  const onFocus = () => {
    let currentTime = Date.now();
    if (user?.created_on !== undefined) {
      var myDate = new Date(user?.created_on!);
      var result = myDate.getTime();

      var mainDiference = currentTime - result;

      var arrNotifications = [];
      if (mainDiference > 86400 * 1000 * 4) {
        arrNotifications.push(
          "You Free trial is going to ends in 3 days, here are our plans if you would like to continue using the app."
        );
      }

      if (mainDiference > 86400 * 1000 * 7) {
        arrNotifications.push(
          "Your Free trial has ended, here are our plans if you would like to continue using the app."
        );
      }

      if (arrNotifications.length > 0) {
        setTimeout(() => {
          ConHeader.setUnread(false);
        }, 2000);
      }
      setnotificationData(arrNotifications);
    }
  };

  useEffect(() => {
    if (Platform.OS !== "web") {
      let s = Navigation.events().registerComponentDidAppearListener(
        (x: { componentId: any }) => {
          if (x.componentId === props.componentId) {
            onFocus();
          }
        }
      );
      // @ts-ignore
      return () => s.remove();
    } else {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <View style={{ paddingLeft: 15 }}>
        <Header title="Notifications" noPadHorizontal />
      </View>
      {loading && (
        <View
          style={{
            position: "absolute",
            marginLeft: -rw(16),
            alignItems: "center",
            height: "100%",
            backgroundColor: "rgba(127,127,127,0.5)",
            width: rw(375),
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size={"large"} color="black" />
        </View>
      )}

      <FlatList
        data={notificationData}
        renderItem={({ item }) => <NotificationCard mainData={item} />}
        keyExtractor={(item: any) => item.id}
      />
    </>
  );

  function NotificationCard({ mainData }: any) {
    return (
      <TouchableOpacity
        onPress={() =>
          navhelper.push("ManageSubscriptionsScreen", { email: user?.email })
        }
      >
        <View
          style={{
            backgroundColor: rgba(0, 0, 0, 0.07),
            borderRadius: 10,
            margin: rw(8),
            marginVertical: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "Outfit",
              fontSize: fs(18),
              fontWeight:
                clientStorage.getItem("NotificationRead") === undefined ||
                clientStorage.getItem("NotificationRead") === null
                  ? "600"
                  : "400",
              color: "#333333",
              padding: 10,
            }}
          >
            {mainData}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

coreOptions(NotificationScreen, {
  useSafeAreaView: true,
  noBottomBar: true,
});
