import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './AppNew.css';
import colors from 'res/colors';
import { TouchableOpacity } from 'react-native';
import { fs } from 'core/designHelpers';


function rw(w: number,sw?:number) {
  let percentage = w /(sw || 1440 )* 100

  return percentage.toString() + "vw"
}
function rh(h: number, w: number) {

  let ratio = h / w

  return (100 * ratio * (w / 1440) + 'vw')
}

var Is_Mobile=false;
function AppNew() {

  const windowSize=useWindowSize();
  console.log("win",windowSize);
  Is_Mobile=windowSize.width<760
  let arr = [...Array(3)].map((_, i) => i)

  return (
    <div className="AppNew">
      <div style={{ width: "100vw", padding: 0,background:'url(/img/background.png)',backgroundRepeat:'repeat-y', backgroundSize:"100%",zIndex:0 }}>

      <Frame1 />
        <Section2 />
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6/>
        <Footer/>
      </div>
    </div>
  );
}

function Background() {


  return (<div style={{ position: 'absolute', width: '100vw' }}>
    <div style={{ width: rw(1440), height: rh(945, 1440),position:'absolute',zIndex:-1 }}>
      <img src={'/img/backgroundPic.gif'} style={{ width: rw(1440), height:'945px',objectFit:'cover',opacity:Is_Mobile ?  0.95:undefined, position: 'absolute',backgroundColor:"grey" }} />
      <div style={{ position: "absolute", width: rw(1440), height: '945px', background: "linear-gradient(180deg, rgba(47, 72, 88, 0.5) 96.01%, #E9FAEF 100%)" }} />
    </div>
    <div style={{ width: rw(1440), height: rh(945, 1440),position:'absolute',top:'945px',zIndex:-1 }}>

      <div style={{ position: "absolute", width: rw(1440), height:'945px', background: "linear-gradient(180deg, #E9FAEF 0%, rgba(233, 250, 239, 0) 83.77%)" }} />
    </div>
    

  </div>)
}
function Frame1() {

  return (<>
<Background/>
    <TopBar />
    <div style={{display:'flex',flexDirection: Is_Mobile ? 'row':'column',position:'relative'}}>
    <div style={Is_Mobile ? 
      { marginTop:'70px', width: '90vw', marginLeft:'5vw',marginRight:'5vw' }
     :{ marginTop:'110px', width:rw(769), alignSelf:"center"}}>
      <p style={{ fontSize: Is_Mobile ? "36px":"48px",margin:0,padding:0,textAlign:'center', fontWeight: '900', color: 'white' }}>You’ve Conditioned Your Body...</p>
      
      <p style={{fontSize: Is_Mobile ? "36px":"48px",margin:0,padding:0,textAlign:'center', fontWeight: '900', color:'#B8D8C5' }}>It’s Time to Condition Your Mind</p>
   
      <div style={{ height: '10px' }} />
      <p style={{ margin:0,fontSize:Is_Mobile?'16px': '24px',textAlign:'center', fontWeight: '500', lineHeight: '130%', color: 'white' }}>
        We see the work you put into getting your body in the finest form possible. We invite you to do the same for your mind. Sign up and book a session with one of our mental wellness counselors and coaches.
      </p>
      <div style={{ marginTop: '50px',display:'flex',justifyContent:'center',alignSelf:'center'}}>
         <img className="popup-btn" onClick={()=>{ 
          let popup=document.getElementById('ytPopup')
          if (popup)
         popup.className+=" appear"  
          
          }} style={{alignSelf:'center'}} src='/svgs/play_circle_filled.svg' />
      </div>
    </div>
    {/* <div style={{width:Is_Mobile ?'90vw':'35vw',position:'absolute',bottom:Is_Mobile? '-140px':undefined,right:'5vw',marginTop:'100px',marginLeft:'5vw',height:Is_Mobile ? "180px":'400px',backgroundColor:'black'}}>
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UML-Epo6jzI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div> */}
    </div>  

    <div style={{ marginTop: Is_Mobile ? "150px":"225px", marginLeft: rw(50) }}>
      <label style={{ fontSize: '18px', color: 'white' }}>Get the App</label>
      <div style={{ display: "flex", flexDirection: 'row', marginTop: '8px', }}>
        <TouchableOpacity>
          <img src="/img/GooglePlayBadgeSoon.png" style={{width:Is_Mobile ?'40vw': rw(135), height:Is_Mobile ? undefined: rh(40, 135) }} />
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>window.open("https://apps.apple.com/us/app/game-on-app-llc/id6443446110","_blank")} >
          <img src="/img/AppStoreBadge.png" style={{ marginLeft: Is_Mobile ? '15px': rw(12),width:Is_Mobile ?'40vw': rw(135), height:Is_Mobile ? undefined: rh(40, 135) }} />
        </TouchableOpacity>

      </div>

    </div>

    <div style={{ marginTop: "50px", width: rw(1340), height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', marginLeft: rw(50) }} />
    <div id="ytPopup" className="video-popup">
      <div className="popup-bg"></div>
      <div className="popup-content">
        {/* <!--         <p className="popup-title">Youtube</p> --> */}
        <iframe id="yTFrame" src="https://www.youtube.com/embed/UML-Epo6jzI" className="video"></iframe>
          <div className='popup-btn' onClick={()=>{ 
          let popup=document.getElementById('ytPopup')
          if (popup)
         popup.className="video-popup"  
         let ytFrame=document.getElementById('yTFrame')
         if (ytFrame){
          //@ts-ignore
          ytFrame.src=""
          //@ts-ignore
          ytFrame.src="https://www.youtube.com/embed/UML-Epo6jzI"
         }
          
          }} style={{marginTop:'30px',cursor:"grab",color:'white',padding:'10px',borderRadius:2,border:'1px solid rgba(255,255,255,0.5)',fontSize:'16px'}}>X{'\t  '}Close</div>
      </div>
    </div>
  </>
  )

}

function Section2() {
  return (<>
    <div style={{ marginTop:Is_Mobile ? 0:  '70px', display: "flex", flexDirection:Is_Mobile ? 'column' :'row', paddingLeft: rw(50), paddingRight: rw(50) }}>
      <img style={{  marginTop:Is_Mobile?'90px': undefined, width:Is_Mobile?'90vw': rw(343), height:Is_Mobile ? rh(1835, 343): rh(435, 343), objectFit: 'cover' }} src='/img/go_card.png' />
      <img style={{  width:Is_Mobile?'90vw': rw(343), height:Is_Mobile ? undefined: rh(435, 343), marginLeft:Is_Mobile?0: rw(30), }} src='/img/athlete.png?v1' />
      <div style={{ marginTop: "54px", marginLeft: rw(53), width:Is_Mobile?'85vw': rw(571) }}>
        <label style={{ fontSize: '48px', fontWeight: '900', color: colors.darkGreen }}>It's <label style={{ color: colors.lightGreen }}>Game On!</label></label>
        <br />

        <br />
        <div style={{ height: '5px' }} />
        <label style={{ fontSize: '18px', fontWeight: '500', lineHeight: '130%', color: colors.dark }}>
          Game On! is a mental wellness app designed to help athletes and fitness enthusiasts have the support they’ve been searching for, right at their fingertips, with the help of mental wellness coaches or counselors.
          <br />
          <div style={{ height: '20px' }} />
          When the whistle is blown, when the clock runs out, when it’s the final play, we don’t say   “game over” , but “Game On!”
        </label>
        <div style={{ marginTop: '50px', display: "flex", flexDirection: 'row' }}>
          <BetaButton />

        </div>

      </div>


    </div>
    <div style={{width:'100%',display:'flex',justifyContent:'center'}} >
          <img  src={`/svgs/as_seen_on${Is_Mobile ? "_mobile":""}.svg`} style={{mixBlendMode:'multiply',alignSelf:'center', marginTop:'30px' }} />
    </div>

    <div style={{ marginTop: "70px", width: rw(1340), height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', marginLeft: rw(50) }} />
  </>
  )

}
function Section3() {
  return (<>
    <div style={{ marginTop: '70px', display: "flex", flexDirection:Is_Mobile ? 'column': 'row', paddingLeft: rw(50), paddingRight: rw(50) }}>
      <img style={{ width:Is_Mobile ? '90vw': rw(426), height: Is_Mobile ? undefined:rh(426, 426), objectFit: 'cover' }} src='/img/quad_a.png' />

      <div style={{display:'flex',marginTop:Is_Mobile ? '20px':undefined,justifyContent:"space-between",flexDirection:'column', paddingLeft: rw(30), paddingRight: rw(30), width: Is_Mobile ? '90vw': '100%' }}>
        <div>
        <label style={{ fontSize: '32px', fontWeight: '900', color: colors.darkGreen }}>What We Offer</label>
        <br />



        <label style={{ fontSize: '14px', fontWeight: '500', lineHeight: '130%', color: colors.dark }}>
          We have qualified mental wellness counselors and coaches who have been in your shoes and understand the struggles that an athlete and fitness enthusiast has been through.
          <br /><div style={{height:'10px'}} />
          You choose your coach or counselor.
          <br /><div style={{height:'10px'}} />
          Schedule sessions that fit your life and meet with your coach or counselor anywhere, anytime through video or phone on Game On! APP  or website.

        </label>
        </div>
        {!Is_Mobile &&<div style={{width:"100%"}}>
          <div style={{width:"100%",display:'flex',marginBottom:'5px'}}>
        <label style={{width:'100%',fontSize: '32px', fontWeight: '900', color: colors.darkGreen,textAlign:"left" }}>Did you know?</label>
        </div>




        <label style={{ fontSize: '14px', fontWeight: '500', lineHeight: '130%', color: colors.dark }}>
        Over 50% of college athletes have reported depression                  
                

        <br /><div style={{height:'5px'}} />
          Only 10% of college athletes seek mental health services

          <br /><div style={{height:'5px'}} />
          Over 35% of professional athletes struggled with mental health 
        </label>
        </div>}
       

      </div>

      <img style={{marginTop: Is_Mobile ? '50px':undefined , width:Is_Mobile ? '90vw': rw(426), height: Is_Mobile ? undefined:rh(426, 426), objectFit: 'cover' }} src='/img/quad_b.png' />
      {Is_Mobile &&<div style={{width:"100%",marginTop:'15px'}}>
          <div style={{width:"100%",display:'flex',marginBottom:'5px'}}>
        <label style={{width:'100%',fontSize: '32px', fontWeight: '900', color: colors.darkGreen }}>Did you know?</label>
        </div>




        <label style={{ fontSize: '14px', fontWeight: '500', lineHeight: '130%', color: colors.dark }}>
        Over 50% of college athletes have reported depression                  
                

        <br /><div style={{height:'5px'}} />
          Only 10% of college athletes seek mental health services

          <br /><div style={{height:'5px'}} />
          Over 35% of professional athletes struggled with mental health 
        </label>
        </div>}

    </div>
    <div style={{ marginTop: "70px", width: rw(1340), height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', marginLeft: rw(50) }} />
  </>
  )

}
function Section4() {
  return (<>
    <div style={{ marginTop: '70px',borderRadius:'50px',width:rw(1340),alignItems:"center", display: "flex", flexDirection: 'column',background:"url(img/texture.png)",backgroundColor:"rgba(233, 250, 239, 1)",marginLeft:rw(50) }}>
    <div  style={{marginTop:"70px"}}>
    {!Is_Mobile && <img  src={ 'svgs/card.svg'} style={{width:rw(1140),height:rh(289,1140),marginBottom:"70px"}}/>}
    {Is_Mobile && <img  src={ 'svgs/card_vertical.svg'} style={{width:'85vw',marginBottom:"70px"}}/>}
    <BetaButton style={{width:"100%",marginBottom:"70px",marginTop:"50px"}}/>
    </div>
    </div>
    <div style={{ marginTop: "45px", width: rw(1340), height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', marginLeft: rw(50) }} />
  </>
  )

}
function Section5() {

  const texts=[
'Sign up via our website or mobile app',
'Choose the right subscription plan for you',
'Book a session with a mental wellness coach or counselor'
  ]
  return (<>
    <div style={{ marginTop: '70px',width:rw(1340),marginLeft:rw(50) }}>
     <label  style={{fontWeight:"900",fontSize:'32px'}}>Get Mental Healthcare in 3 Steps</label>
      <div style={{width:"100%",height:Is_Mobile ? '600px':rh(252,1440),marginTop:'30px',display:"flex",flexDirection:Is_Mobile ?'column':'row',justifyContent:'space-between'}}>
      {[...new Array(3)].map((_,i)=> (  <div key={i} style={{height:"100%",width:Is_Mobile ? '90vw':rw(346),display:"flex",flexDirection:"column"}}>
            <img  style={Is_Mobile ? {width:'100px',height:'100px'}: {width:rw(100),height:rh(100,100)}} src={"/svgs/f$.svg".replace('$',(i+1).toString())}/>
            <label style={{fontWeight:"500",fontSize:"24px",color:colors.dark,opacity:0.5,marginTop:"10px",marginBottom:"10px"}}>{texts[i]}</label>
            <LearnButton style={{width:"100%"}} text="Access Beta"/>
        </div>))
}
      </div>
    
    </div>
    <img  src='/img/gallery.png' style={{width:'100vw',marginTop:'70px'}}/>
    {/* {!Is_Mobile  && <img  src="/svgs/everyone_loves.svg"  style={{width:rw(1340),height:rh(564,1340),marginTop:'70px',marginLeft:rw(50)}}/>}
    {Is_Mobile  && <img  src="/svgs/everyone_loves_vertical.svg"  style={{width:'90vw',marginTop:'70px',marginLeft:'5vw'}}/>} */}
  </>
  )

}
function Section6() {
  let scale=1.25
  return (<>
    <div id="downloadNow" style={{ marginTop: '70px',overflow:'hidden',borderRadius:'50px',width:rw(1340), display: "flex",justifyContent:'space-between', flexDirection:Is_Mobile ? 'column': 'row',background:"url(img/texture.png)",backgroundColor:"rgba(233, 250, 239, 1)",marginLeft:rw(50),height:Is_Mobile ? '750px':undefined }}>
    <div style={{ marginTop: Is_Mobile ? '30px': '134px',marginBottom:"135px", width: Is_Mobile ? '85vw': rw(441), marginLeft: rw(100) }}>
      <label style={{ fontSize: Is_Mobile ? '24px': '48px', fontWeight: '900', color: colors.darkGreen }}>Mental Wellness support, Anywhere!</label>
      
      <br />
      <div style={{ height: '10px' }} />
      <label style={{ fontSize: Is_Mobile ?'14px': '24px', fontWeight: '500', lineHeight: '130%', color: colors.dark ,opacity:0.5}}>
      Take Game On! with you everywhere by downloading our mobile app.  Quality mental wellness care, all at the touch of a screen.
      </label>
      <div style={{ marginTop: Is_Mobile ? "48px":"48px", marginLeft:Is_Mobile ? 0: rw(0) }}>
      <label style={{ fontSize: '18px', color: '#18191F', }}>Get the App</label>
      <div style={{ display: "flex", flexDirection: 'row', marginTop: '8px', }}>
        <TouchableOpacity>
          <img src="/img/GooglePlayBadgeSoon.png" style={{ width:Is_Mobile ?'40vw': rw(135), height:Is_Mobile ? undefined: rh(40, 135) }} />
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>window.open("https://apps.apple.com/us/app/game-on-app-llc/id6443446110","_blank")} >
          <img src="/img/AppStoreBadge.png" style={{ marginLeft: Is_Mobile ? '15px': rw(12),width:Is_Mobile ?'40vw': rw(135), height:Is_Mobile ? undefined: rh(40, 135)  }} />
        </TouchableOpacity>

      </div>

    </div>
    </div>
    <div  style={{display:'flex',marginLeft:Is_Mobile ? '30px':rw(32),flex:1,flexDirection:'row'}} >
   
    <div style={{display:'flex', flex:0,height:'100%'}}>
    <img  style={{flex:0,width:`${265*scale}px`,height:`${575*scale}px`,marginTop:Is_Mobile ? `-${105*scale}px`:`-${105*scale}px`,objectFit:'contain'}}  src='/img/Phone Mockup 1.png'/>
    </div>
    
    {!Is_Mobile &&   <div style={{display:'flex',marginLeft:rw(42), flex:0,height:'100%',alignItems:'flex-end'}}>
        <img  style={{flex:0,width:`${265*scale}px`,height:`${575*scale}px`,marginBottom:`-${130*scale}px`,objectFit:'contain'}}  src='/img/Phone Mockup 2.png'/>
        </div>}
    
        </div>
        </div>
    {!Is_Mobile &&      <img id="support" style={{marginTop:'141px',marginLeft:rw(50),width:rw(1340),height:rh(518,1340)}}  src="/svgs/faqs.svg"/>}
    {Is_Mobile &&      <img id="support" style={{marginTop:'141px',marginLeft:'5vw',width:'90vw'}}  src="/svgs/faqs_vertical.svg"/>}
    
    {!Is_Mobile && 
      <div style={{
          marginLeft:rw(50),
          marginRight:rw(50),
          backgroundColor: 'white',
          width: rw(1340),
          borderRadius: "30px",
          marginTop: rh(10, 1340),
          paddingBottom: rh(10, 1340),
          display:'flex',
          flexDirection:'column',flexWrap:'wrap',margin:'auto',
          transition:'all 0.2 linear',justifyContent:'space-around',
          height:'auto'
        }}>

          <div style={{flex:'1 1 50px',height:'50px',display:'flex',flexDirection:'row',marginLeft: rw(50),marginTop: rh(40, 1340),marginBottom: rh(10, 1340)}}>
            <div>
                <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '28px', color: colors.darkGreen }}>Contact Information</span>
            </div>
          </div>
          <div style={{flex:'1 1 100px', display:'flex',flexDirection:'row',marginLeft: rw(50),marginTop: rh(10, 1340),marginBottom: rh(10, 1340)}}>
            <div style={{height: "75%", width:'25%',display:'flex',flexDirection:'column',flex:'1 1',borderRight:'1px ridge'}}>
                <div style={{display:"flex",flexDirection:'column',flex:'1 1 100px'}}>
                  <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Call or Message Us</span>
                  <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(30,1340) }}>(979) GameOn1</span>
                  <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(30,1340) }}>(979) 426-3661</span>
                </div>
            </div>  
            <div style={{flex:'1 1 100px',width:'25%',display:"flex",flexDirection:'column',marginLeft:rw(20)}}>
                <div style={{display:"flex",flexDirection:'column',marginLeft:rw(20),flex:'1 1 100px'}}>
                  <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Support</span>
                  <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(16,1340) }}>support@thegameonapp.com</span>
                </div>
                <div style={{display:"flex",flexDirection:'column',marginLeft:rw(20),marginTop:rh(20,1340),flex:'1 1 100px'}}>
                    <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Marketing</span>
                    <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(16,1340) }}>marketing@thegameonapp.com</span>
                </div>
            </div>
            <div style={{flex:'1 1 100px',width:'25%',display:"flex",flexDirection:'column',marginLeft:rw(20)}}>
                <div style={{display:"flex",flexDirection:'column',marginLeft:rw(20),flex:'1 1 100px'}}>
                  <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Accounting</span>
                  <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(16,1340)}}>accounting@thegameonapp.com</span>
                </div>
                <div style={{display:"flex",flexDirection:'column',marginLeft:rw(20),marginTop:rh(20,1340),flex:'1 1 100px'}}>
                  <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Inquiries</span>
                  <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(16,1340) }}>prinquiries@thegameonapp.com</span>
                </div>
            </div>
            <div style={{flex:'1 1 100px',width:'25%',display:"flex",flexDirection:'column',marginLeft:rw(20)}}>
                <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'gray' }}>Info</span>
                <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:rh(16,1340) }}>info@thegameonapp.com</span>
            </div>
          </div>
    </div>}

    {Is_Mobile &&
      <div style={{
          marginLeft:rw(50),
          marginRight:rw(50),
          backgroundColor: 'white',
          width:rw(1340),
          borderRadius: '20px',
          marginTop: rh(10, 1340),
          paddingBottom: rh(10, 1340),
          display: "flex",
          justifyContent: "space-between"
        }}>
          <div  style={{flex:1,display:'flex',flexDirection:'column',marginLeft:rw(30),marginTop:'30px',marginBottom:'30px'}}>
                <div  style={{display:'flex', flexDirection:'column', flex:1, alignItems: 'center'}}>
                    <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '24px', color: colors.darkGreen }}>Contact Information</span>
                </div>
                <div style={{display:'flex',flexDirection:'column',paddingTop:'12px',flex:1, alignItems: 'center'}}>
                    <div style={{display:"flex",flexDirection:'column',marginTop:'30px',flex:1,alignItems:'center',justifyContent: "space-between"}}>
                      <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Call or Message Us</span>
                      <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>(979) GameOn1</span>
                      <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>(979) 426-3661</span>
                    </div>
                    <div style={{display:"flex",flexDirection:'column',marginTop:'30px',flex:1,alignItems:'center',justifyContent: "space-between" }}>
                      <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Email Us</span>
                      <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>support@thegameonapp.com</span>
                    </div>
                    <div style={{display:"flex",flexDirection:'column',marginTop:'30px',flex:1,alignItems:'center',justifyContent: "space-between"}}>
                    <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Marketing</span>
                    <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>marketing@thegameonapp.com</span>
                    </div>
                    <div style={{display:"flex",flexDirection:'column',marginTop:'30px',flex:1,alignItems:'center',justifyContent: "space-between"}}>
                    <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Accounting</span>
                    <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>accounting@thegameonapp.com</span>
                    </div>
                    <div style={{display:"flex",flexDirection:'column',marginTop:'30px',flex:1,alignItems:'center',justifyContent: "space-between"}}>
                    <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Prinquiries</span>
                    <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>prinquiries@thegameonapp.com</span>
                    </div>
                    <div style={{display:"flex",flexDirection:'column',marginTop:'30px',flex:1,alignItems:'center',justifyContent: "space-between"}}>
                    <span style={{ fontFamily: "Outfit", fontWeight: "600", fontSize: '18px', color: 'grey' }}>Info</span>
                    <span style={{ fontFamily: "Outfit", fontWeight: "400", fontSize: '16px', color: 'rgba(51, 51, 51, 1)',marginTop:'16px' }}>info@thegameonapp.com</span>
                    </div>
                </div>
          </div>
        </div>
    }
 <div style={{ marginTop: "50px", width: rw(1340), height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', marginLeft: rw(50) }} />
    <div id="about" style={{
      marginLeft:rw(50),
      marginRight:rw(50),
      width: rw(1340),
      display:'flex',
      flexDirection:'column',
      alignItems: 'center'
    }}>
      
       <div style={{textAlign:'center'}}> 
        <p style={{fontSize:'26px',fontWeight:'bold',margin:0,marginTop:'70px',marginBottom:'10px'}}>About Us</p>
       </div>
       <div style={{textAlign:'center'}}>
        <p style={{fontSize:'18px',color:colors.lightGreen,margin:0,marginBottom:'30px'}}>When the game stops, it's not game over, but GameOn!</p>
       </div>
    </div>
    
    
    <div id="aboutInfo" style={{
      marginLeft:rw(50),
      marginRight:rw(50),
      backgroundColor: 'rgba(47, 72, 88, 1)',
      width: rw(1340),
      borderRadius: "20px",
      marginTop: rh(10, 1340),
      // paddingBottom: rh(40, 1340),
      display:'flex',
      flexWrap: 'wrap',
    }}>
      <div  style={{backgroundColor:'transparent',marginLeft:rw(Is_Mobile ? 120 : 50),marginTop: rh(Is_Mobile ? 220: 40, 1340)}}>
        <img style={{height:'50px'}} src="/svgs/logo_white.svg" />
      </div>
      <div style={{marginTop: rh(  10, 1340),marginLeft:rw(Is_Mobile ? 120 : 50),marginRight:rw(Is_Mobile ? 120 : 30),color:'white',font:'outfit',fontSize:fs(28), alignItems:'flex-start',display:'flex', flexDirection: 'column'}}>
        <p style={{fontSize:'18px',opacity:0.8}}>Game On! is a mental wellness app dedicated to connecting athletes to sports-specialized mental wellness coaches and counselors. Through Game On!, athletes can access quality mental wellness services in a safe digital environment. Athletes are matched with professionals who understand their sport as well as other pertinent criteria. Athletes can signup for GameOn!  Without inputting any credit card information and receive a free consultation. GameOn! Offers different subscription options to make it easy and cost-effective for the athletes. College athletes are offered a discount subscription when they sign up using their university email.</p>
        <p style={{fontSize:"18px",opacity:0.8}}>Game On! App LLC, is founded by Lories Khoury, a Licensed Psychotherapist specializing in Sport Counseling. Lories has worked in mental health for over a decade offering services to individuals, couples, children, and families at their homes, schools, community and clinics. Lories was a former athlete turned fitness enthusiast, who struggled with her mental health after a competition was over. She understands the stigma, both professionally and personally, of the impact mental health has on an athlete. </p>
        <p style={{fontSize:"18px",opacity:0.8}}>Lories started Game On! because she is passionate about helping athletes and fitness enthusiasts access the mental wellness support they seek privately and confidentially.</p>
        <div style={{padding:'30px',alignSelf:Is_Mobile ?'center':undefined,border:"1px solid rgba(255,255,255,0.1)",borderRadius:'30px',marginTop:'50px',marginBottom:"100px"}}>
          <img style={{width:Is_Mobile? rw(238,375):rw(320)}} src='/profile.png'></img>
          <br/>
          <span style={{fontSize:'24px'}}>Lories Khoury</span>
          <br/>
          <span style={{fontSize:'20px',opacity:0.5}}>Founder / CEO</span>
        </div>
      </div>
            
    </div>

  </>
)}

function TopBar() {

  let sections = ["Mental Wellness Professionals"           ,  "Support"  , "About Us", "Login"]
  let locs     = ["https://gameonllc.bamboohr.com/careers"  , "#support"  , "#about"  , "/portal"]

  let bookmarks: any = ["What_is_Game_On"]

  function setOpened(arg0: boolean): void {
    throw new Error('Function not implemented.');
  }

  return (<div style={{ width: "100vw", height: "60px",zIndex:12 }} >

    <div style={{ height: "100%", marginLeft: rw(50), marginTop: '20px', marginRight: Is_Mobile ? rw(50):"50px", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between" }} >

      <img src='/svgs/go_long.svg?v1' 
      style={{ height:Is_Mobile ? '26px': rh(26, 150), width:Is_Mobile?'150px': rw(150), alignSelf: "center", display: "flex" }} className="App-logo" alt="logo" />

     {Is_Mobile ? <MobileMenu locs={locs} sections={sections}/>
     :
     <div style={{ display: "flex" }}>
        <div style={{ alignItems: 'center', justifyContent: "space-between", display: "flex" }} >
          {sections.map((s, i) => {
            let loc="";
            if (Is_Mobile){
              loc='/portal'
            }
            else if (i==0){
              loc="https://gameonllc.bamboohr.com/careers"
            }
            else if (i==1){
              loc="#support"
            }
            else if(i==2){
              loc ="#about"
            }
            else if (i==3)
            {
              loc="/portal"
            }
            
            return (<span key={s}  style={{marginLeft:Is_Mobile ? "2vw":undefined, marginRight: Is_Mobile ? 0:'30px', whiteSpace: 'nowrap', padding: '10px' }}><a href={loc} style={{ width: '100%',color: i == 0 ? 'rgba(184, 216, 197, 1)': 'white', fontWeight: '600', fontSize:Is_Mobile ? '16px': '16px', textDecoration: "none" }} > {s}</a></span>)
          })}
        </div>
        <BetaButton />
      </div>
      }

    </div>
  </div>



  )
}
function MobileMenu(p:{locs:Array<string>,sections:Array<string>}){
    const [opened,setOpened]=useState(false)

  return(<>
    <TouchableOpacity onPress={()=>setOpened(true)}>
    <img src="/svgs/menu.svg" />
    </TouchableOpacity>
    <div style={{display:opened ?'block':'none',position:'fixed',backgroundColor:colors.darkGreen,height:'100vh',top:0,left:0,zIndex:1000,width:"100vw"}} >
      <div style={{marginTop:'32px',paddingLeft:'16px',paddingRight:'16.5px',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginBottom:'30px'}}>
    <img src='/svgs/go_long.svg' 
      style={{ height:Is_Mobile ? '26px': rh(26, 150), width:Is_Mobile?'150px': rw(150), alignSelf: "center", display: "flex" }} className="App-logo" alt="logo" />
         <TouchableOpacity onPress={()=>setOpened(false)}> 
         <div style={{color:'white',display:'flex',fontSize:'32px'}}>X</div>
         </TouchableOpacity>
      </div>
      {p.sections.map((x,i)=>{

        return (<a key={x} href={p.locs[i]} style={{color:'white',marginLeft:'16px',marginRight:'16px',padding:'20px',backgroundColor:'rgba(57, 81, 96, 1)',marginBottom:'20px',textDecoration:"none",display:'flex'}}>{x}</a>)
      })

      }
    </div>
    </>)
}
function MainCard() {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "80vmin", height: "80vmin", backgroundColor: "green", backgroundSize: "cover", backgroundPosition: "center", marginTop: "64px", marginBottom: "64px", boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.5)" }} >
        <div style={{ position: "relative", height: "100%", width: "100%", backgroundColor: "rgba(255,255,255,0.9)", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <img src={process.env.PUBLIC_URL + "AppFinal.png"} style={{ height: "100%", width: "100%" }} />
          <div style={{ position: "absolute", height: "25vmin", width: "40vmin", top: "6vmin", right: "3vmin" }} >
            <img src={logo} style={{ height: `${2.08 * 3}vmin`, width: `${10.68 * 3}vmin`, alignSelf: "center", display: "flex", marginBottom: "2vmin" }} className="App-logo" alt="logo" />
            <span style={{ width: "40%", textAlign: "center", fontSize: "0.8em", fontFamily: "appFontBold" }}>Connecting Athletes to Mental Health Professionals at your fingertips.</span>
          </div>
          {/* <img src={logo} style={{height:208/2,width:1068/2,alignSelf:"center",display:"flex",marginBottom:"60px"}} className="App-logo" alt="logo" /> 
    <span style={{width:"40%",textAlign:"center"}}>Connecting Athletes to Mental Health Professionals at you fingertips.</span> */}
        </div>


      </div>
    </div>

  )
}

function Section({ index, s }: any) {
  return (<div id={s.title.replace(/ /g, "_").replace("!", "")}>

    <div id={s.title.replace(/ /g, "_").replace("!", "")} style={{ width: "100vw", height: "60vh", backgroundColor: index % 2 == 0 ? "#c5c6d055" : "rgba(255,255,255,0.7)", display: "flex", justifyContent: "center", alignItems: "center" }}>

      <div style={{ width: "90%", height: "80%", display: "flex", alignItems: "center", flexDirection: index % 2 == 0 ? "row" : "row-reverse" }}>
        <div style={{ height: "100%", flex: 0.75 }} >
          <h2>{s.title}</h2>
          <span style={{ fontSize: "0.8em" }}>{s.description}</span>

        </div>
        <div style={{ height: "90%", flex: 0.3, justifyContent: "center", alignItems: "center", display: "flex" }} >
          <img src={s.img} style={{
            height: "300px", width: "300px", objectFit: "cover", marginRight: index % 2 == 0 ? 0 : "80px", borderRadius: 24,
            boxShadow: "2px 2px 10px 1px black",
            border: "4px solid rgb(244,254,233)"
          }} />
        </div>

      </div>
    </div>
  </div>)
}

function Sponsers({ index }: any) {
  return (
    <div style={{ width: "100vw", height: "60vh", marginTop: "32px", flexDirection: "column", backgroundColor: "rgb(39,66,77,0.15)", display: "flex", alignItems: "center" }}>
      <span style={{ color: "white", marginTop: "6vh", fontSize: "1.4em", fontFamily: "appFontBold" }}>Prospects</span>
      <span style={{ color: "white", marginTop: "3vh", fontSize: "0.8em" }}>Our application will help following clients</span>
      <div style={{ width: "60%", justifyContent: "space-between", display: "flex", marginTop: "10vh" }}>
        <img style={{ height: "10vh" }} src={"https://asmi.org/wp-content/themes/kronos/assets/img/logo.svg"} />
        <img style={{ height: "12vh" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/IOC_Logo.svg/2341px-IOC_Logo.svg.png" />
        <img style={{ height: "10vh" }} src="https://www.nata.org/sites/default/files/nata_logo_final_blue.png" />
      </div>
    </div>)
}


function SocialMedia({ index }: any) {
  return (
    <div id="contact" style={{ width: "100vw", height: "40vh", marginTop: "32px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <span style={{ flex: 0, whiteSpace: "nowrap", fontSize: "1.5em" }}>Connect with Us</span>
      <div style={{ width: "25vw", marginTop: "5vh", height: "12vh", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 25, display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: "1vmin" }} >
        <img style={{ backgroundColor: "rgb(39,66,77)", height: "5vmin", padding: "2vmin", width: "5vmin", borderRadius: 25 }} src={process.env.PUBLIC_URL + "svgs/icon-facebook.svg"} />
        <img style={{ backgroundColor: "rgb(39,66,77)", height: "5vmin", padding: "2vmin", width: "5vmin", borderRadius: 25 }} src={process.env.PUBLIC_URL + "svgs/icon-instagram.svg"} />
        <img style={{ backgroundColor: "rgb(39,66,77)", height: "5vmin", padding: "2vmin", width: "5vmin", borderRadius: 25 }} src={process.env.PUBLIC_URL + "svgs/icon-twitter.svg"} />
        <img style={{ backgroundColor: "rgb(39,66,77)", height: "5vmin", padding: "2vmin", width: "5vmin", borderRadius: 25 }} src={process.env.PUBLIC_URL + "svgs/icon-linkedin.svg"} />
      </div>

    </div>)
}
function EmailUs({ index }: any) {
  return (
    <div style={{ width: "100vw", marginTop: "32px", marginBottom: "80px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <span style={{ flex: 0, whiteSpace: "nowrap", fontSize: "1.5em" }}>Write to Us</span>
      <div style={{ minWidth: "20vw", paddingLeft: "16px", paddingRight: "16px", marginTop: "2vh", height: "4vh", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 25, display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: "1vmin" }} >
        <p>info@gameonappllc.com</p>
      </div>

    </div>)
}

function Footer() {

  return (<div style={{ width: "100vw", height:Is_Mobile ? undefined: "269px", backgroundColor: colors.lightGreen, display: "flex", justifyContent: "space-between",paddingTop:'55px', marginTop: "100px" ,flexDirection: Is_Mobile ? 'column':'row'}} >
   
   {!Is_Mobile && <div style={{marginLeft:rw(50)}}>
    <img src="/svgs/logo_white.svg" />
    <div style={{marginTop:'30px'}}>
    <a href="#about" style={{textDecoration:"none",color:'white',fontSize:'16px' }} >About Us</a>
      {/* <a href="#downloadNow" style={{textDecoration:"none",color:'white',fontSize:'16px' }} >Download Now</a> */}
      <a href="https://drive.google.com/file/d/1PtEl5l0E9Fs2jggOM_LDIuBTU4YulL7Q/view?usp=sharing" style={{textDecoration:"none",color:'white',fontSize:'16px',marginLeft:'30px' }} >Media Guide</a>
      <a href="#support" style={{textDecoration:"none",color:'white',fontSize:'16px',marginLeft:'30px' }} >Support</a>
      <a href="/policy.pdf" style={{textDecoration:"none",color:'white',fontSize:'16px',marginLeft:'30px' }} >Privacy Policy</a>
    </div>
    <div style={{marginTop:'12px'}}>
     
      {/* <a href="https://gameonllc.bamboohr.com/careers" style={{textDecoration:"none",color:'white',fontSize:'16px',marginLeft:'30px' }} >Careers</a> */}
      {/* <a href="/terms.pdf" style={{textDecoration:"none",color:'white',fontSize:'16px',marginLeft:'30px' }} >Terms & Conditions</a> */}
     
    </div>
    <div style={{marginTop:'60px'}}>
    <a style={{textDecoration:"none",color:'white',fontSize:'16px' }} >© 2023 Game On!</a>
    </div>
    </div>
}
{Is_Mobile && <div style={{marginLeft:'5vw',width:'90vw'}}>
    <img src="/svgs/logo_white.svg" />
    <div style={{display:'flex',marginTop:'30px',width:'90vw',flexDirection:'column',marginBottom:'70px'}}>
      {/* <a style={{textDecoration:"none",color:'white',fontSize:'16px' ,width:'40vw',marginBottom:'30px'}} >Download Now</a> */}
      <a href="#about" style={{textDecoration:"none",color:'white',fontSize:'16px',width:'40vw',marginBottom:'30px' }} >About Us</a>
      <a href="https://drive.google.com/file/d/1PtEl5l0E9Fs2jggOM_LDIuBTU4YulL7Q/view?usp=sharing" style={{textDecoration:"none",color:'white',fontSize:'16px',width:'40vw',marginBottom:'30px' }} >Media Guide</a>
   
      {/* <a href="https://gameonllc.bamboohr.com/careers" style={{textDecoration:"none",color:'white',fontSize:'16px',width:'40vw' }} >Careers</a> */}
      {/* <a  href="/terms.pdf" style={{textDecoration:"none",color:'white',fontSize:'16px',width:'40vw' ,marginBottom:'30px'}} >Terms</a> */}
      <a  href="#support" style={{textDecoration:"none",color:'white',fontSize:'16px',width:'40vw',marginBottom:'30px'  }} >Support</a>
      <a  href="/policy.pdf" style={{textDecoration:"none",color:'white',fontSize:'16px',width:'40vw' }} >Privacy Policy</a>
    </div>
   
    </div>
}
<div style={{marginRight:Is_Mobile ? '5vw':rw(60),width: Is_Mobile ? '90vw':undefined,marginLeft:Is_Mobile ? '5vw':undefined}}>
<div>
      <label style={{ fontSize: '18px', color: 'white' }}>Follow Us</label>
      <div style={{ display: "flex", flexDirection: 'row', marginTop: '16px', justifyContent:'space-between'}}>
        <TouchableOpacity onPress={()=>window.open("https://twitter.com/TheGameOnApp","_blank")}>
          <img src="/svgs/br-twitter.svg" style={{ width:Is_Mobile ? '40px': rw(30), height:Is_Mobile ? '40px': rh(30, 135) }} />
        </TouchableOpacity>
    
        <TouchableOpacity onPress={()=>window.open("https://www.linkedin.com/company/game-on-app-llc/","_blank")} >
          <img src="/svgs/br-linkedin.svg" style={{ width:Is_Mobile ? '40px': rw(30), height:Is_Mobile ? '40px': rh(30, 135) }}  />
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>window.open("https://instagram.com/thegameonapp","_blank")} >
          <img src="/svgs/br-instagram.svg" style={{ width:Is_Mobile ? '40px': rw(30), height:Is_Mobile ? '40px': rh(30, 135) }}  />
        </TouchableOpacity>
        <TouchableOpacity  onPress={()=>window.open("https://www.facebook.com/thegameonapp","_blank")} >
          <img src="/svgs/br-facebook.svg" style={{ width:Is_Mobile ? '40px': rw(30), height:Is_Mobile ? '40px': rh(30, 135) }}  />
        </TouchableOpacity>
        <TouchableOpacity  onPress={()=>window.open("https://www.tiktok.com/@thegameonapp?lang=en","_blank")} >
          <img src="/svgs/br-tiktok.svg" style={{ width:Is_Mobile ? '40px': rw(30), height:Is_Mobile ? '40px': rh(30, 135) }}  />
        </TouchableOpacity>
        <TouchableOpacity  onPress={()=>window.open("https://www.youtube.com/channel/UCkDx2NkBhKAAUVH7L2RYoxw","_blank")} >
          <img src="/svgs/br-yt.svg" style={{ width:Is_Mobile ? '40px': rw(30), height:Is_Mobile ? '40px': rh(30, 135) }}  />
        </TouchableOpacity>
      </div>

    </div>
    <div style={{marginTop:'20px'}}>
      <label style={{ fontSize: '18px', color: 'white' }}>Get the App</label>
      <div style={{ display: "flex", flexDirection: 'row', marginTop: '16px', }}>
        <TouchableOpacity>
          <img src="/img/GooglePlayBadgeSoon.png" style={{width:Is_Mobile ?'40vw': rw(135), height:Is_Mobile ? undefined: rh(40, 135)}} />
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>window.open("https://apps.apple.com/us/app/game-on-app-llc/id6443446110","_blank")}>
          <img src="/img/AppStoreBadge.png" style={{ marginLeft: Is_Mobile ? '15px': rw(12),width:Is_Mobile ?'40vw': rw(135), height:Is_Mobile ? undefined: rh(40, 135) }} />
        </TouchableOpacity>

      </div>

    </div>
    {Is_Mobile &&  <div style={{marginTop:'40px',marginBottom:'30px'}}>
    <a style={{textDecoration:"none",color:'white',fontSize:'16px' }} >© 2023 Game On!</a>
    </div>}
</div>
  </div>

  )
}
function BetaButtonOld({style}:{style:any}) {
  
  return (<TouchableOpacity style={{ height:'60px', width: rw(198), backgroundColor: colors.darkGreen, borderRadius: '50px', justifyContent: "center", alignItems: 'center', display: "flex" ,...style}}>
    <label href='/portal' style={{ color: "white", fontSize: "16px" }} >Access Beta</label>
  </TouchableOpacity>)
}
function BetaButton(_:any){
  return null;
}
function LearnButtonOld({style,text}:any) {

  return (<TouchableOpacity style={{ height: '60px', width: rw(198), borderWidth: '2px', borderColor: colors.darkGreen, borderRadius: '50px', justifyContent: "center", alignItems: 'center', display: "flex",...style }}>
    <label href='/portal' style={{ fontWeight:"600", color: colors.darkGreen, fontSize: "16px" }} >{text||"Learn More"}</label>
  </TouchableOpacity>)
}
function LearnButton(_:any){
  return null;
}
export default AppNew;

// NOTE: Move to new file to reuse
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      
      windowSize.height!=window.innerHeight && windowSize.width!=window.innerWidth  && setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
