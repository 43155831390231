import { coreOptions } from 'core/core';
import { fs, FULL_SCREEN, rh, rw } from 'core/designHelpers';
import navhelper from 'core/navhelper';
import React, { Component, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Button, Clipboard, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import {
  TwilioVideoLocalView,
  TwilioVideoParticipantView,
  TwilioVideo
} from 'react-native-twilio-video-webrtc';
import WebView from 'react-native-webview';
import colors from 'res/colors';
import TwilioVideoCallWeb from './TwilioVideoCallWeb';

var mounted=false;
const TwilioVideoCall = (props:any) => {

  const [compact,setCompact]=useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [status, setStatus] = useState('disconnected');
  const [participants, setParticipants] = useState(new Map());
  const [videoTracks, setVideoTracks] = useState(new Map());
  const [token, setToken] = useState(props.token);
  const twilioRef = useRef<any>(null);

  const _onConnectButtonPress = () => {
    twilioRef.current?.connect({ accessToken: token });
    setStatus('connecting');
  }
  
  useEffect(()=>{
    mounted=true
    _onConnectButtonPress()
    return ()=>{
        mounted=false
    }
  },[])
  const _onEndButtonPress = () => {
    mounted=false
    twilioRef.current.disconnect();
    props.onEnd()
    //navhelper.goBack()
  };

  const _onMuteButtonPress = () => {
    twilioRef.current
      .setLocalAudioEnabled(!isAudioEnabled)
      .then((isEnabled:any) => setIsAudioEnabled(isEnabled));
  };
  const _onVideoButtonPress = () => {
    twilioRef.current
      .setLocalVideoEnabled(!isVideoEnabled)
      .then((isEnabled:any) => setIsVideoEnabled(isEnabled));
  };

  const _onFlipButtonPress = () => {
    twilioRef.current.flipCamera();
  };

  const _onRoomDidConnect = ({roomName, error}:any) => {
    console.log('onRoomDidConnect: ', roomName);

    setStatus('connected');
  };

  const _onRoomDidDisconnect = ({ roomName, error }:any) => {

    console.log('[Disconnect]ERROR: ', error,mounted);
   mounted && navhelper.goBack()
    setStatus('disconnected');
  };

  const _onRoomDidFailToConnect = (error:any) => {
    console.log('[FailToConnect]ERROR: ', error);
    mounted && navhelper.goBack()
    setStatus('disconnected');
  };

  const _onParticipantAddedVideoTrack = ({ participant, track }:any) => {
    console.log('onParticipantAddedVideoTrack: ', participant, track);

    setVideoTracks(
      new Map([
        ...videoTracks,
        [
          track.trackSid,
          { participantSid: participant.sid, videoTrackSid: track.trackSid },
        ],
      ]),
    );
  };

  const _onParticipantRemovedVideoTrack = ({ participant, track }:any) => {
    console.log('onParticipantRemovedVideoTrack: ', participant, track);

    const videoTracksLocal = videoTracks;
    videoTracksLocal.delete(track.trackSid);

    setVideoTracks(videoTracksLocal);
  };
  if (Platform.OS=="web")
  return TwilioVideoCallWeb(props);
  return (
    <View onStartShouldSetResponder={()=>{
      setCompact(false)
      return compact
    }} style={[styles.container,compact ?{ height:rh(215),width:rw(120),top:rh(119),right:rw(16)}:{}]}>
      {
       ( status === 'disconnected') &&
  
      
        <ActivityIndicator color={"black"}  size={"large"}/>
        
  
      }

      {
        (status === 'connected' || status === 'connecting') &&
          <View style={styles.callContainer}>
          {
            status === 'connected' &&
            <View style={styles.remoteGrid}>
              {
                Array.from(videoTracks).slice(-1).map (([trackSid, trackIdentifier],) => {

                  return (
                    <TwilioVideoParticipantView
                      style={styles.remoteVideo}
                      key={trackSid}
                      trackIdentifier={trackIdentifier}
                    />
                  )
                })
              }
            </View>
          }
          { !compact && <>
          <View
            style={styles.optionsContainer}>
           
            <TouchableOpacity
              style={styles.optionButton}
              onPress={_onVideoButtonPress}>
              <Text style={styles.font}>{ isVideoEnabled ? "Video off" : "Video on" }</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionButton}
              onPress={_onMuteButtonPress}>
              <Text style={styles.font}>{ isAudioEnabled ? "Mute" : "Unmute" }</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionButton}
              onPress={_onFlipButtonPress}>
              <Text style={styles.font}>Flip</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionButton}
              onPress={_onEndButtonPress}>
              <Text style={styles.font}>End</Text>
            </TouchableOpacity>
           
          </View>
          <TouchableOpacity onPress={()=>{ setCompact(true) }}  style={{backgroundColor:colors.lightGreen,padding:20,borderRadius:100, position:'absolute',top:rh(50),left:10}}>
              <Text>V</Text>
          </TouchableOpacity>
          <TwilioVideoLocalView
              enabled={true}
              style={styles.localVideo}
            />
         </>}
        </View>
      }

      <TwilioVideo
        ref={ twilioRef }
        onRoomDidConnect={ _onRoomDidConnect }
        onRoomDidDisconnect={ _onRoomDidDisconnect }
        onRoomDidFailToConnect= { _onRoomDidFailToConnect }
        onParticipantAddedVideoTrack={ _onParticipantAddedVideoTrack }
        onParticipantRemovedVideoTrack= { _onParticipantRemovedVideoTrack }
      />
    </View>
  );
}
TwilioVideoCall.connectConfig={noPhoneWindow:true }
const styles=StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor:"#2f4858",
      justifyContent:"center",
      alignItems:"center",
      position:'absolute',
    
      width:'100%',
      height:'100%'
    },
    callContainer: {
      flex: 1,
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
    },
    welcome: {
      fontSize: 30,
      textAlign: "center",
      paddingTop: 40,
    },
    input: {
      height: 50,
      borderWidth: 1,
      marginRight: 70,
      marginLeft: 70,
      marginTop: 50,
      textAlign: "center",
      backgroundColor: "white",
    },
    button: {
      marginTop: 100,
    },
    localVideo: {
      flex: 1,
      width: rw(120),
      height: rh(215),
      position: "absolute",
      right: 10,
      top: rh(50),

    },
    remoteGrid: {
      flex: 1,

      flexWrap: "wrap",

     
    },
    remoteVideo: {
    
      width: '100%',

      flex:1,

   

    },
    optionsContainer: {
      position: "absolute",
      left: 0,
      bottom: 0,
      right: 0,
      height: 100,
      
      flexDirection: "row",
      alignItems: "center",
      justifyContent:'space-between',
      paddingHorizontal:rw(16)
    },
    optionButton: {
      width: 60,
      height: 60,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 100 / 2,
      backgroundColor: '#039590',
      justifyContent: "center",
      alignItems: "center",
    },
    font:{
      fontFamily:"Outfit",
      fontSize:fs(12),
      fontWeight:"400",
      color:"white"
      
    }
  });

 
  coreOptions(TwilioVideoCall,{noBottomBar:true})
  export default TwilioVideoCall