import clientStorage from "core/clientStorage";
import { FULL_SCREEN, rh } from "core/designHelpers";
import navhelper from "core/navhelper";
import { IsValidEmail, IsValidPassword, validateAll } from "core/validators";
import { useState } from "react";
import { SafeAreaView, View } from "react-native";
import env from "res/env";
import cloud from "src/cloud";
import { JumpToSignUp } from "src/commons";
import AppButton from "src/components/AppButton";
import Header from "src/components/Header";
import PasswordField from "src/components/PasswordField";
import TextField from "src/components/TextField";

export default function SignupScreen({ componentId }: any) {
  let id = 0;
  if (__DEV__) {
    // @ts-ignore
    id = Math.round(
      (new Date().getTime() - new Date(2022, 10, 22).getTime()) / 1000
    )
      .toString()
      .slice(-4);
  }
  const [email, setEmail] = useState<string>(
    __DEV__ ? `dev${id}@yopmail.com` : ""
  );
  const [password, setPassword] = useState<string>(__DEV__ ? "Say7names" : "");
  const [cnfrmPassword, setCnfrmPassword] = useState<string>(
    __DEV__ ? "Say7names" : ""
  );
  const [errors, setErrors] = useState<any>({});

  return (
    <View
      style={[
        FULL_SCREEN,
        //{backgroundColor:'rgb(192,216,200)'}
      ]}
    >
      <SafeAreaView style={FULL_SCREEN}>
        <Header
          title="Sign Up"
          leftButton={{
            title: "Login",
            onPress: () => navhelper.push("LoginScreen"),
          }}
        />

        <TextField
          label="Email address"
          value={email}
          placeholder="Email address"
          onChangeText={(newEmail) => setEmail(newEmail)}
          errorMsg={errors["email"]}
        />
        <View style={{ height: rh(20) }} />
        <PasswordField
          label="Password"
          value={password}
          placeholder="Password"
          onChangeText={(newPassword) => setPassword(newPassword)}
          errorMsg={errors["password"]}
        />
        <View style={{ height: rh(20) }} />
        <PasswordField
          label="Re-enter Password"
          placeholder="Re-enter Password"
          value={cnfrmPassword}
          onChangeText={(newPassword) => setCnfrmPassword(newPassword)}
          errorMsg={errors["cnfrmPassword"]}
        />
        <View style={{ height: rh(50) }} />
        <AppButton
          onPress={() => {
            if (env.type !== "mock") {
              let newErrors = validateAll([
                { email, rule: IsValidEmail },
                { password, rule: IsValidPassword },
                {
                  cnfrmPassword,
                  rule: (v) =>
                    v === password ? undefined : "Passwords must match.",
                },
              ]);
              setErrors(newErrors);
              if (Object.keys(newErrors).length) {
                return;
              }
            }

            return new Promise((r, rj) => {
              cloud
                .signup({ email, password })
                .then(async ({ token }) => {
                  if (token) {
                    try {
                      clientStorage.saveItem("lastPassword", password);
                      await JumpToSignUp(token, email).then(r).catch(rj);
                      r(undefined);
                    } catch (e) {
                      rj(e);
                    }
                  }
                })
                .catch(rj);
            });
          }}
          title="Sign Up"
          enabled={
            (!!email && !!password && !!cnfrmPassword) || env.type === "mock"
          }
          loaderDelayMillis={5000}
        />
      </SafeAreaView>
    </View>
  );
}
