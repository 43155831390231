import { Platform } from "react-native"

export default class env_dev{

    static   type:'mock' | 'dev' | 'prod'='dev'
    static serverURL= Platform.OS=='web'
                      ? 'http://localhost:5001/game-on--app-llc/us-central1/'
                      : 'http://192.168.0.104:5001/game-on--app-llc/us-central1/'
    static logLevel:'none' | 'protected' | 'all'='all'
    
    static NO_PAY=true

    static DO_NOT_REQUIRE_DOCS=true
   
}