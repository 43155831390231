// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqBXu7k09_2XW312Ix1OlrEhOzZxolO7g",
  authDomain: "game-on--app-llc.firebaseapp.com",
  databaseURL: "https://game-on--app-llc-default-rtdb.firebaseio.com",
  projectId: "game-on--app-llc",
  // storageBucket: " gameon-staging-fcb9b.appspot.com",
  storageBucket: "game-on--app-llc.appspot.com",
  messagingSenderId: "355125281566",
  appId: "1:355125281566:web:b8e85ce98c50e479d77f0f",
  measurementId: "G-23G8X97PQW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
