import {app,} from "./support"
import {getAuth,signInWithEmailAndPassword} from "firebase/auth"

const _auth=getAuth(app)

function auth(){

    return {
        signInWithEmailAndPassword:(...args:any)=>{

                    return signInWithEmailAndPassword(_auth,args[0],args[1])

        }
    }
}


export default auth