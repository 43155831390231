export default class env_prod {
  static type: "mock" | "dev" | "prod" = "prod";
  static serverURL =
    // "https://us-central1-gameon-staging-fcb9b.cloudfunctions.net/";
    "https://us-central1-game-on--app-llc.cloudfunctions.net/";

  static NO_PAY = true;

  static DO_NOT_REQUIRE_DOCS = true;
}
