import { coreOptions, Overlay } from "core/core";
import { fs, rh, rw } from "core/designHelpers";
import StyleSheetRW from "core/StyleSheetRW";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, Image, KeyboardAvoidingView, Platform, ScrollView, Text, TextInput, Keyboard, Modal, TouchableOpacity } from "react-native";
import colors from "res/colors";
import cloud from "src/cloud";
import AppButton from "src/components/AppButton";
import ConHeader from "src/components/ConHeader";
import SafeAreaInsets from "src/components/SafeAreaInsets";
import { useAppStore } from "src/models/ReduxStore";
import database from "@react-native-firebase/database";
import env from "res/env";
import Header from "src/components/Header";
import TwilioVideoCall from "./consumer/tabs/Chat/TwilioVideoCall";
import navhelper from "core/navhelper";

interface ChatScreenprops {
    is_main_view: boolean;
    to: { email: string, name: string, img: any }
}

var activeCallToken=""
export default function ChatScreen(props: ChatScreenprops) {
    const { to, is_main_view = true } = props
    const [msgs, setMsgs] = useState<Array<any>>([]);
    const [txt,setTxt]=useState<string>();

    const email=useAppStore(s=>s.user?.email) || ""
    const name=useAppStore(s=>s.user?.full_name) || ""
    const threadId=[email,to.email].sort().join()
    const [joined,setJoined]=useState(false)
    
    const _scrollViewRef=useRef<ScrollView>();

    const JOIN_CALL=true;

    useEffect(()=>{
        let path=`/${env.type.toUpperCase()}/threads/${threadId.replace(/\./g,'{dot}')}`
        console.log(path)
        let ref=database().ref(path)
        // ref.once("value",p=>{
        //  setMsgs(   Object.values<any>(  p.val() || {})?.sort((a,b)=>a.time_secs-b.time_secs)) 
        // })
       let r= ref.on("child_added",sp=>{
        
            msgs.push(sp.val())
           setMsgs([...msgs])
        })

       return ()=>{
            ref.off("child_added",r)
       }
    },[])
   // console.log(msgs)
    return (<KeyboardAvoidingView
        keyboardVerticalOffset={SafeAreaInsets.BOTTOM + 10}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1 }}>
        
        {is_main_view && <ConHeader title="Chat" rightComponent={() => (<Image style={{ height: rh(40), width: rh(40), borderRadius: 100 }} source={{ uri: to.img }} />)} />}
        {/* <Header title={to.name} noPadHorizontal/> */}
        <ScrollView
        ref={_scrollViewRef}
        showsVerticalScrollIndicator={false}
         onContentSizeChange={() => _scrollViewRef.current?.scrollToEnd({animated: true})}>
        
            {(!msgs || !msgs.length) && <Text style={{ fontFamily: 'Outfit', fontSize: fs(12), width: '100%', textAlign: "center" }}>This is the begining of this thread.</Text>}
            {msgs.map(({msg,from,time_secs},i)=>{

                const ME= from==email
                let dateTime=moment(time_secs*1000)
                let time=dateTime.format("hh:mm a")
                let date=moment().isSame(dateTime,'date')?"": dateTime.format('D MMM')
                return (<View  key={i} style={{width:"100%",justifyContent:ME ? "flex-end":"flex-start",flexDirection:"row",marginBottom:rh(10)}}>
                   <View style={{alignItems:ME ? "flex-end":"flex-start"}}>
                   <View style={[{backgroundColor : ME ? "#e9faef":"#2f4858",borderRadius:20},ME ? {borderBottomRightRadius:0}:{borderBottomLeftRadius:0}]}>
                    <Text style={{fontFamily:"Outfit",fontWeight:"400",fontSize:fs(16),color: ME ?"black":"white",marginVertical:rh(15),marginHorizontal:rw(20)}}  >{msg}</Text>
                 
                    </View>
                    <Text style={{fontFamily:"Outfit",fontSize:fs(12),color:'#333',marginTop:rh(5)}} >{time} {date}</Text>
                    </View>
                </View>)
            })

            }
        </ScrollView>
        <View style={{ marginLeft: -rw(16), width: is_main_view ? rw(375): '110%', backgroundColor: '#e9faef', height: rh(JOIN_CALL ? 105+74:105)  }} >
       { is_main_view && JOIN_CALL &&    <View testID="Call Join" style={{width:"100%",backgroundColor:'#e9faef',height:rh(74),borderBottomWidth:1,flexDirection:'row',justifyContent:"space-between",alignItems:"center"}} >
            <View>
                <Text style={{fontFamily:"Outfit",fontSize:fs(16),paddingLeft:rw(16),color:colors.lightGreen}} >Call in progress</Text>
                </View>
                {!joined && <AppButton title="Join"  onPress={async ()=>{
                        await cloud.twilio.getToken({to:to.email}).then(x=>{
                            
                            if(x.token){
                               if (Platform.OS!="web"){
                                activeCallToken=x.token
                                setJoined(true)
                               }
                               else {
                                 navhelper.push("TwilioVideoCall",{
                                    token:x.token,
                                    to: to,
                                    is_provider: false
                                 })
                               }
                               
                            }
                        })
                }}  style={{width:rw(160)}} />}

            
            </View>}
            <TextInput value={txt} onChangeText={t=>setTxt(t?.trimStart())} style={{ width: "100%", flex: 1, fontFamily: 'Outfit', fontSize: fs(16), color: 'black', paddingHorizontal: rw(16) }}>




            </TextInput>
            <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: "row" }} >
                <View />
                <AppButton onPress={async ()=>{
                    try{
                    await cloud.send({to:to.email,name,time_secs:Math.round(moment().utc().valueOf()/1000),isUser:true,msg:txt!,threadId})
                    setTxt("")
                    }
                    finally{
                        Keyboard.dismiss()
                    }

                }} enabled={!txt ? false:undefined} title="Send" style={{ width: rw(71), height: rh(30), backgroundColor: undefined, borderWidth: 1, borderColor:txt ?colors.lightGreen:'#aaa', color: txt ?colors.lightGreen:'#aaa'}} />
            </View>
        </View>
        <Overlay >
     { joined &&  <TwilioVideoCall onEnd={()=>setJoined(false)} token={activeCallToken} />}
     </Overlay>
    </KeyboardAvoidingView>)

}

coreOptions(ChatScreen, {
    useSafeAreaView: true,
    noBottomBar: true,
    getBodyStyle: () => ({ paddingHorizontal: rw(16) }),

})
const styles = StyleSheetRW.create(() => ({

}))