import { Database, DataSnapshot, getDatabase, onValue, ref,onChildAdded } from "firebase/database";
import { app } from "./support";
export const db = getDatabase(app   );
const listnersList:any[]=[]
const unsubList:any[]=[]
export default function database(){

    return {
    ref:(path:string)=>{

        return {
            once:(type:string)=>{

                return new Promise<DataSnapshot> ((r,rj)=>{

                    let l=onValue( ref(db,path),(sp)=>{

                        l()
                        r(sp)
                    },)
                })
            },
            on:(type:string,listener:any)=>{

                listnersList.push(listener)
                unsubList.push(
                 type=="child_added" ? 
                 onChildAdded(ref(db,path),(sp)=>{
                        listener(sp)
                 })
                 :
                 onValue(ref(db,path),(sp)=>{
                        listener(sp)
                })
                )

                return listener

            },
            off:(type:string,listener:any)=>{
                
                let i=listnersList.indexOf(listener)
                console.log(">off>",i,unsubList)
                listnersList.splice(i,1)
                unsubList[i]()
                unsubList.splice(i,1)
            }
        }
    }
}
}