// @ts-ignore
import { SafeAreaView } from "react-native";
import { View, Text } from "react-native";
// @ts-ignore
import VideoPlayer from "react-video-js-player";
import Header from "./components/Header";
import { FULL_SCREEN, fs, rgba, rh, rw } from "core/designHelpers";
import { TouchableOpacity } from "react-native";
import navhelper from "core/navhelper";
import ConsumerTabs from "./screens/consumer/tabs/ConsumerTabs";

export default function BeforeQuestionVideoScreen() {
  const onVideoEnd = () => {
    navhelper.push("HearAboutUs");
  };

  return (
    <View style={[FULL_SCREEN]}>
      <SafeAreaView style={FULL_SCREEN}>
        <Header title="GameOn!" />
        <VideoPlayer
          controls={true}
          autoplay={true}
          src={require("./videos/MATCH_GAMEON.mp4")}
          width="320"
          height="720"
          onEnd={() => onVideoEnd()}
        />
        <TouchableOpacity
          style={{
            width: rw(343),
            marginHorizontal: rw(16),
            marginVertical: rw(16),
            backgroundColor: rgba(47, 72, 88, 1),
            height: rh(54),
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => navhelper.push("HearAboutUs")}
        >
          <Text
            style={{
              fontFamily: "Outfit",
              fontSize: fs(14),
              fontWeight: "600",
              color: "white",
            }}
          >
            Skip
          </Text>
        </TouchableOpacity>
      </SafeAreaView>
    </View>
  );
}
